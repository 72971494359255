import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import SwiperItemsList from "../../ContentBuilder/SwiperList/SwiperItemsList"
const HomeDesignRelatedItems = () => {
    const allRelatedRaw = useStaticQuery(graphql`
    {
        allSilverStripeDataObject(filter: {className: {eq: "Undigital__Objects__HomeDesignRule"}}) {
            nodes {
              UndigitalHomeDesignRule {
                name
                smallTitle
                productType
                isSystemRule
                jsonData
              }
            }
          }
    }`);
    const allRelatedNodes = allRelatedRaw.allSilverStripeDataObject.nodes;   
    var _allData = [];
    if(allRelatedNodes.length){
        var i = 0
        for (; allRelatedNodes[i]; ) {
            let _item = allRelatedNodes[i].UndigitalHomeDesignRule;
            _allData.push(_item);
            i++;          
        }
    }
  return (
        <div>
            {_allData.map((item, idx) => (
                <SwiperItemsList key={`related-${idx}`} title={item.name} titleClass={`${item.smallTitle == 1 ? 'title-small' : 'title-default'}`} navClass={item.productType} items={item.jsonData} />
            ))}
        </div>          
    )
}

export default HomeDesignRelatedItems
